import { RouteLocationNormalized } from 'vue-router'

const routers = [
  {
    // 전체 커뮤니티
    name: 'Community.Front',
    path: '/:Section/Front',
    redirect: { name: 'Community.Front.All' },
    meta: {
      layout: 'Default',
      useLocalMenu: true
    },
    children: [
      {
        // 전체 커뮤니티
        name: 'Community.Front.All',
        path: 'All',
        component: () => import('mik-community/src/pages/front/Front.vue')
      },
      {
        // 개설 신청 목록
        name: 'Community.Front.Applications',
        path: 'Applications',
        component: () => import('mik-community/src/pages/front/application/List.vue')
      },
      {
        // 개설 신청
        name: 'Community.Front.New',
        path: 'New',
        component: () => import('mik-community/src/pages/front/application/Form.vue')
      },
      {
        // 개설 신청 수정
        name: 'Community.Front.Edit',
        path: ':EstablishID/Edit',
        component: () => import('mik-community/src/pages/front/application/Form.vue')
      },
      {
        // 개설 신청 View
        name: 'Community.Front.View',
        path: ':EstablishID',
        component: () => import('mik-community/src/pages/front/application/View.vue')
      },
      {
        // My Community 목록
        name: 'Community.Front.MyCommunity',
        path: 'MyCommunity',
        component: () => import('mik-community/src/pages/front/MyCommunityList.vue')
      }
    ]
  },
  /**
   * V1 커뮤니티 게시판 URL 루틴
   * 일반 게시판: Community/Group/{CommunityRelativeUrl}/Board/{layoutId}
   * 일정 게시판: Community/Group/{CommunityRelativeUrl}/Scheduler/Scheduler
   * V2 커뮤니티 게시판 URL 루틴
   * 일반 게시판: Community/{CommunityRelativeUrl}/Board/{layoutId}
   * 일정 게시판: Community/{CommunityRelativeUrl}/Scheduler/{layoutId}
   */
  // {
  //   path: '/Group',
  //   meta: {
  //     layout: 'Community'
  //   },
  //   children: [
  //     {
  //       name: 'Home.OldBoard',
  //       path: ':communityUrl/Board/:layoutId',
  //       props: (route) => {
  //         boardUrl: route.params.boardUrl
  //       },
  //       component: () => import('mik-community/src/controls/Board.vue')
  //     },
  //     {
  //       name: 'Home.OldSchedule',
  //       path: ':communityUrl/Scheduler/:layoutId',
  //       component: () => import('mik-community/src/controls/ScheduleBoard.vue')
  //     },
  //     {
  //       name: 'Home.OldBoardView',
  //       path: ':communityUrl/Board/:layoutId/View/:itemId',
  //       component: () => import('mik-community/src/controls/BoardItem.vue')
  //     }
  //   ]
  // },
  {
    path: '/:communityUrl',
    meta: {
      layout: 'Community',
      communityManage: false
    },
    redirect: { name: 'Community.Home' },
    children: [
      {
        name: 'Community.Home',
        path: 'Home',
        component: () => import('mik-community/src/pages/community/Home.vue')
      },
      {
        // 일반 게시판
        name: 'Community.Home.Board',
        path: 'Board/:layoutId',
        // props: (route) => ({
        //   pageNumber: route.query.pageNumber ?? 1,
        //   searchType: route.query.searchType ?? '',
        //   searchKeyword: route.query.searchKeyword ?? '',
        //   searchPeriod: route.query.searchPeriod ?? ''
        // }),
        meta: {
          boardType: 'Community.Board'
        },
        component: () => import('mik-community/src/controls/board/List.vue')
      },
      {
        // 일정 게시판
        name: 'Community.Home.Scheduler',
        path: 'Scheduler',
        component: () => import('mik-community/src/controls/board/Scheduler.vue')
      },
      {
        name: 'Community.Home.BoardView',
        path: 'Board/:layoutId/View/:itemId',
        meta: {
          boardType: 'Community.Board'
        },
        component: () => import('mik-community/src/controls/board/View.vue')
      },
      {
        name: 'Community.Home.BoardEdit',
        path: 'Board/:layoutId/:mode/:itemId?',
        meta: {
          boardType: 'Community.Board'
        },
        component: () => import('mik-community/src/controls/board/Edit.vue')
      },
      {
        // 갤러리 게시판
        name: 'Home.Gallery',
        path: 'Gallery/:layoutId',
        props: (route: RouteLocationNormalized) => ({
          pageNumber: route.query.pageNumber ?? 1,
          searchType: route.query.searchType ?? '',
          searchKeyword: route.query.searchKeyword ?? '',
          searchPeriod: route.query.searchPeriod ?? ''
        }),
        component: () => import('mik-community/src/controls/board/GalleryList.vue')
      },
      {
        name: 'Home.GalleryView',
        path: 'Gallery/:layoutId/View/:boardItemId',
        component: () => import('mik-community/src/controls/board/GalleryView.vue')
      },
      {
        name: 'Home.GalleryEdit',
        path: 'Gallery/:layoutId/:mode/:boardItemId?',
        component: () => import('mik-community/src/controls/board/GalleryEdit.vue')
      }
    ]
  },
  {
    path: '/:communityUrl/Management',
    redirect: { name: 'Community.Management.Home' },
    meta: {
      layout: 'Community',
      communityManage: true
    },
    children: [
      {
        name: 'Community.Management.LocalMenu',
        path: 'LocalMenu',
        component: () => import('mik-community/src/pages/community/management/LocalMenu.vue')
      },
      {
        name: 'Community.Management.Group',
        path: 'Group',
        component: () => import('mik-community/src/pages/community/management/Group.vue')
      },
      {
        name: 'Community.Management.Member',
        path: 'Member',
        component: () => import('mik-community/src/pages/community/management/Member.vue')
      },
      {
        name: 'Community.Management.Join',
        path: 'Join',
        component: () => import('mik-community/src/pages/community/management/Join.vue')
      },
      {
        name: 'Community.Management.Home',
        path: 'Home',
        component: () => import('mik-community/src/pages/community/management/Home.vue')
      },
      {
        name: 'Community.Management.Stats',
        path: 'Stats',
        component: () => import('mik-community/src/pages/community/management/Stats.vue')
      },
      {
        name: 'Community.Management.Information',
        path: 'Info',
        component: () => import('mik-community/src/pages/community/management/Information.vue')
      },
      {
        name: 'Community.Management.Theme',
        path: 'Theme',
        component: () => import('mik-community/src/pages/community/management/Theme.vue')
      }
    ]
  }
]

export default routers
